import { type RouteLocationRaw } from 'vue-router'

import type { BreadcrumbsColorEnum } from '@/service/__generated-api'

export default function useBreadcrumbs() {
  const color = useState<BreadcrumbsColorEnum>(
    'breadcrumbsColor',
    () => 'brown'
  )
  const links = useState<{ title: string; to: RouteLocationRaw }[]>(
    'breadcrumbsLinks',
    () => []
  )

  return {
    color,
    links
  }
}
